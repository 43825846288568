import BaseService from "./base-service";
import axios from "@/plugins/axios.plugin";

class FilesService extends BaseService<any> {
  get endpoint() {
    return "/files";
  }

  async upload(formData) {
    const response = await axios.post(`${this.endpoint}/upload`, formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        responseType: "json"
      })
    console.log(response)
    return response
  }
}

export default new FilesService();