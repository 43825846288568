import LivestreamService from "@/services/LivestreamService";
import SettingsService from "@/services/SettingsService";

const Livestreams = {
  namespaced: true,
  state: {
    livestreams: [],
    livestream: {},
    active_livestream: {}
  },
  getters: {
    allLivestreams: (state: { livestreams: any }) => state.livestreams,
    livestream: (state: {livestream: any }) => state.livestream,
    active: (state: {livestreams: any, active_livestream}) => {
      state.active_livestream = state.livestreams.find(livestream => livestream.active === true)
      return state.active_livestream
    },
  },
  mutations: {
    SET_CURRENT(state: { livestream: any }, livestream: any) {
      state.livestream = livestream
    },
    SET_ALL(state: { livestreams: any }, livestreams: any) {
      state.livestreams = livestreams
    },
    SET_ACTIVE(state: { active_livestream: any }, active_livestream: any) {
      state.active_livestream = active_livestream
    },
    EMPTY_CURRENT(state: { livestream: any }) {
      state.livestream = {
        id: "",
        active: false,
        code: {
          en_US: "",
          nl_NL: ""
        },
        type: {
          en_US: "",
          nl_NL: ""
        },
        title: {
          en_US: "",
          nl_NL: ""
        },
        description: {
            en_US: "",
            nl_NL: ""
        },
        api_key: "",
        api_secret: "",
        leaveUrl: "",
        password: "",
        lang: "",
        chat: {
            type: {
              en_US: "",
              nl_NL: ""
            },
            code: {
              en_US: "",
              nl_NL: ""
            },
        } 
      }
    },
  },
  actions: {
    async fetchById({ commit }: any, id: string) {
      const livestream = await LivestreamService.fetchOne(id);
      console.log(livestream)
      commit("SET_CURRENT", livestream);
    },
    async fetchAll({ commit }: any) {
      const livestreams = await LivestreamService.fetchAll();
      commit("SET_ALL", livestreams);
    },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("livestream", data);
      commit("SET_ALL", response.setting_value);
    }
  }
} as any

export default Livestreams