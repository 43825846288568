<template>
  <footer>
    <div class="footer-container">
      <b-row
        class="sponsors justify-content-md-center"
        v-if="sponsors.length > 0 && showSponsors"
      >
        <b-col cols="2" v-for="(sponsor, index) in sponsors" :key="index">
          <div class="image-wrapper">
            <span class="helper"></span
            ><img :src="sponsor.image" :alt="sponsor.name" width="200" />
          </div>
        </b-col>
      </b-row>
      <p>
        Powered by
        <a class="tribespot-link" href="https://www.tribespot.be">Tribespot</a>
      </p>
    </div>
    <!-- <cookie-law v-if="language == 'fr_FR'" :buttonLink="`/cookie-privacy-fr`" theme="dark-lime"></cookie-law>
        <cookie-law v-if="language == 'nl_NL'" :buttonLink="`/cookie-privacy-nl`" theme="dark-lime"></cookie-law> -->
  </footer>
</template>

<script>
// import CookieLaw from 'vue-cookie-law'
export default {
  // components: { CookieLaw },
  computed: {
    language() {
      return this.$root.$i18n.locale;
    },
    sponsors() {
      return this.$store.getters["Sponsors/all"];
    },
    showSponsors() {
      return this.$store.getters["Features/sponsors"];
    },
  },
  // created() {
  //   // this.$store.dispatch("Sponsors/fetchAll");
  // },
};
</script>

<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);

// footer {

//   width:100%;
//   bottom: 0;
//   position: absolute;
// }

footer {
  // position:fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $color-primary;

  .footer-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    color: #fff;
  }
  p {
    font-size: 15px;
    margin: 1em;
  }

  .tribespot-link {
    color: $text_color_title;
  }
  .tribespot-link:hover {
    color: #e2810a;
  }
}

.sponsors {
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  .image-wrapper {
    height: 100%;
    text-align: center;
    display: inline-block;
    .helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }

    img {
      width: 100%;
      vertical-align: middle;
    }
  }
}
</style>
