import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/auth.module";
import Config from "./modules/config.module";
import Popup from "./modules/popup.module";
import Translations from "./modules/translations.module";
import Dash from "./modules/dashboard.module";
import Livestreams from "./modules/livestream.module";
import Attachments from "./modules/attachments.module";
import Styling from "./modules/styling.module";
import Users from "./modules/users.module";
import Notifications from "./modules/notifications.module";
import Features from "./modules/features.module";
import Sponsors from "./modules/sponsors.module";
import Logs from "./modules/logs.module";
import Languages from "./modules/languages.module";
import Header from "./modules/header.module"
import Pages from "./modules/pages.module"

Vue.use(Vuex);

const store = new Vuex.Store<any>({
  modules: {
    Config,
    Auth,
    Popup,
    Translations,
    Livestreams,
    Attachments,
    Dash,
    Styling,
    Users,
    Notifications,
    Features,
    Sponsors,
    Languages,
    Logs,
    Header,
    Pages
  },
});

export default store;
