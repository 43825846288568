import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
const Livestream = () => import("@/views/Livestream.vue");
import store from "@/store/index";
const BasicProfile = () => import("@/views/BasicProfile.vue");
const Auth0Profile = () => import("@/views/Auth0Profile.vue");
const Admin = () => import("@/views/Admin.vue");
const Authentication = () => import("@/views/Authentication.vue");
const ComingSoon = () => import("@/views/ComingSoon.vue");
const MobileChat = () => import("@/views/MobileChat.vue");
const ZoomPlayer = () => import("@/components/video/ZoomPlayer.vue");
const CookiePrivacyDoc_NL = () =>
  import("@/components/GDPR/CookiePrivacyDoc_NL.vue");
const CookiePrivacyDoc_FR = () =>
  import("@/components/GDPR/CookiePrivacyDoc_FR.vue");
const LanguagePick = () => import("@/views/LanguagePick.vue");
const ArenaSocial = () => import("@/views/ArenaSocial.vue");
const Dashboard = () => import("@/views/Dashboard.vue");
const StaticPage = () => import("@/views/StaticPage.vue");
const HomeConfig = () => import("@/components/admin/home/HomeConfig.vue");
const StyleConfig = () => import("@/components/admin/StyleConfig.vue");
const WherebyConfig = () => import("@/components/admin/WherebyConfig.vue");
const FeatureConfig = () => import("@/components/admin/FeatureConfig.vue");
const Notifications = () => import("@/components/admin/Notifications.vue");
const RegistrationForm = () => import("@/components/BaseForm.vue");
const LivestreamConfig = () =>
  import("@/components/admin/LivestreamConfig.vue");
const AdminLogin = () => import("@/views/AdminLogin.vue");
const Users = () => import("@/components/admin/Users.vue");
const RegistrationConfig = () =>
  import("@/components/admin/RegistrationConfig.vue");
const UserDetail = () => import("@/components/admin/UserDetail.vue");
const LivestreamDetail = () =>
  import("@/components/admin/LivestreamDetail.vue");
const StaticPages = () => import("@/components/admin/StaticPages.vue");
const StaticPagesDetail = () => import("@/components/admin/StaticPagesDetail.vue");
const HeaderConfig = () => import("@/components/admin/HeaderConfig.vue");


import { authGuard } from "../auth0/authGuard";
import { loadLanguageAsync } from "../plugins/i18n";

Vue.use(VueRouter);

let routes: Array<RouteConfig> = [];

routes = [
  {
    path: "/languagepick",
    alias: ["/language"],
    name: "languagePick",
    meta: {
      authRequired: false,
    },
    component: LanguagePick,
  },
  {
    path: "/",
    alias: ["/home", "/dashboard"],
    name: "dashboard",
    component: Dashboard,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/livestream",
    name: "livestream",
    meta: {
      authRequired: true,
    },
    component: Livestream,
  },
  {
    path: "/profile-b",
    name: "profileBasic",
    meta: {
      authRequired: true,
    },
    component: BasicProfile,
  },
  {
    path: "/profile-a",
    name: "profileAuth0",
    meta: {
      authRequired: true,
    },
    component: Auth0Profile,
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      authRequired: true,
    },
    component: Admin,
    children: [
      {
        path: "login",
        name: "admin-login",
        component: AdminLogin,
      },
      {
        path: "home-config",
        name: "home-config",
        component: HomeConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "home-config",
        name: "home-config",
        component: HomeConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "livestream-config",
        name: "livestream-config",
        component: LivestreamConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "livestream-detail",
        name: "livestream-detail",
        component: LivestreamDetail,
        props: true,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "style-config",
        name: "style-config",
        component: StyleConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "feature-config",
        name: "feature-config",
        component: FeatureConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "whereby-config",
        name: "whereby-config",
        component: WherebyConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "users/:id",
        name: "user-detail",
        component: UserDetail,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "users",
        name: "users",
        component: Users,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "registration",
        name: "registration-config",
        component: RegistrationConfig,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "static-pages",
        name: "static-pages",
        component: StaticPages,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "page-detail",
        name: "page-detail",
        component: StaticPagesDetail,
        props: true,
        meta: {
          sidebar: true,
        },
      },
      {
        path: "header-config",
        name: "header-config",
        component: HeaderConfig,
        meta: {
          sidebar: true,
        },
      },
    ],
  },
  {
    path: "/authentication",
    name: "authentication",
    component: Authentication,
  },
  {
    path: "/registration",
    name: "RegistrationForm",
    component: RegistrationForm,
  },
  {
    path: "/zoom",
    name: "zoom",
    component: ZoomPlayer,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/coming-soon",
    name: "comingSoon",
    component: ComingSoon,
  },
  {
    path: "/mobile",
    name: "mobileChat",
    component: MobileChat,
  },
  {
    path: "/cookie-privacy-nl",
    name: "cookie",
    component: CookiePrivacyDoc_NL,
  },
  {
    path: "/cookie-privacy-fr",
    name: "cookie",
    component: CookiePrivacyDoc_FR,
  },
  {
    path: "/social",
    name: "social",
    component: ArenaSocial,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/pages/:link/:lang",
    name: "static-page",
    props: true,
    component: StaticPage
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("Features/fetchAll");
  await store.dispatch("Languages/fetchAll");
  await store.dispatch("Translations/getTranslations");
  await store.dispatch("Auth/me");
  await loadLanguageAsync();
  const loginRequired = store.getters["Features/loginRequired"];
  const authentication = store.getters["Features/all"].authentication?.type;
  const comingSoon = store.getters["Features/all"].comingSoon;
  const languagePick = store.getters["Features/all"].languagePick;
  const landingPage = store.getters["Features/all"].landingPage;
  const language = localStorage.getItem("lang");
  const isAdmin = store.getters["Auth/isAdmin"];
  const isLoggedIn = store.getters["Auth/isLoggedIn"];

  console.log(to, isAdmin, loginRequired, isLoggedIn);
  
  if (to.name === "admin-login") {
    return next();
  }
  
  if (to.path.startsWith("/admin") && isAdmin) {
    return next();
  }
  
  if (to.path.startsWith("/admin") && !isAdmin) {
    return next({ name: "admin-login" });
  }
  
  if (!loginRequired && to.name === "authentication") {
    return next({ name: "dashboard" });
  }
  
  if (!loginRequired || !to.meta?.authRequired) {
    return next();
  }

  if (loginRequired && isLoggedIn) {
    return next();
  }

  if (authentication === "auth0") {
    authGuard(to, from, next);
  } else if (authentication !== "none") {
    return next({ name: "authentication" });
  }

  if (landingPage && to.name === "/") {
    return next({ name: "dashboard" });
  }

  if (languagePick && to.path !== "/language" && !language) {
    return next({ name: "languagePick" });
  }

  if (comingSoon && to.name !== "coming-soon" && !isAdmin) {
    return next({ name: "coming-soon" });
  }

  if (to.path == "/coming-soon" && !comingSoon) {
    return next({ name: "home" });
  }

  return next();
});

export default router;
