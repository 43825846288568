import SettingsService from "@/services/SettingsService";

const Pages = {
  namespaced: true,
  state: {
    pages: [],
    page: {}
  },
  getters: {
    allPages: (state: { pages: any }) => state.pages,
    page: (state: {page: any }) => state.page,
  },
  mutations: {
    SET_CURRENT(state: { page: any }, page: any) {
      state.page = page
    },
    SET_ALL(state: { pages: any }, pages: any) {
      state.pages = pages
    },
    EMPTY_CURRENT(state: { page: any }) {
      state.page = {
        active: false,
        title: {
            en_US: "",
            nl_NL: ""
        },
        link: "",
        html: ""
      }
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
        const pages = await SettingsService.fetchOnePublic("pages");
        commit("SET_ALL", pages.setting_value);
      },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("pages", data);
      commit("SET_ALL", response.setting_value);
    }
  }
} as any

export default Pages