<template>
  <div></div>
</template>
<script>
import Pusher from "pusher-js"; // import Pusher
import Vue from "vue";

export default {
  name: "Popup",
  computed: {
    loggedInUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    userId() {
      return this.loggedInUser?.id;
    },
  },
  methods: {
    makeToast(title, message, url) {
      if (Vue.compile) {
        const res = Vue.compile(this.template);
        message = res.render;
      }
      this.$root.$bvToast.toast([message], {
        title,
        href: url,
        appendToast: true,
        variant: "info",
        autoHideDelay: 20000,
        toaster: "b-toaster-bottom-right",
      });
    },
    subscribe() {
      let pusher = new Pusher("0a1daf43ba675b3f0fff", {
        cluster: "eu",
      });
      pusher.subscribe(`dm`);
      pusher.bind("all", (data) => {
        this.makeToast(data.title, data.message, data.url);
      });
      pusher.bind(this.userId, (data) => {
        this.makeToast(data.title, data.message, data.url);
      });
    },
  },
  created() {
    this.subscribe();
  },
};
</script>
<style lang="scss">
.toast {
  color: black;
  a {
    color: black;
  }
}
</style>
