import axios from "axios";

const Config = {
  namespaced: true,
  state: {
    settings: [],
    setting: {
      client: {
        name: "",
        logo: "",
        logo2: "",
        background: "",
        admin: "",
        notification: "",
        profilepicture: "",
      },
      languages: [],
      chat: {
        id: "",
        type: ""
      },  
      interactive_livestream: {
        type: "",
      },
      support: {
        type: "",
        appToken: "",
        host: "",
      },
      social: {
        type: "",
      },
    },
  },
  getters: {
    allSettings: (state: { settings: any }) => state.settings,
    setting: (state: { setting: any }) => state.setting,
    authenticationType: (state: { setting: any }) =>
      state.setting.features.authentication,
    loginRequired: (state: { setting: any }) =>
      state.setting.features.loginRequired,
  },
  mutations: {
    SET_SETTING(state: { setting: any }, setting: any) {
      state.setting = setting;
    },
    SET_ALL_SETTINGS(state: { settings: any }, settings: any) {
      state.settings = settings;
    },
  },
  actions: {
    async getSetting({ commit }: any) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/settings/settings`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          responseType: "json",
        }
      );
      commit("SET_SETTING", response.data.setting_value);
    },
    async getAllSettings({ commit }: any) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/settings`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          responseType: "json",
        }
      );
      commit("SET_ALL_SETTINGS", response.data);
    },
    async updateSetting({ commit }: any, data: any) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_URL}/settings/settings`,
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          responseType: "json",
        }
      );
      commit("SET_SETTING", response.data.setting_value);
    },
  },
} as any;

export default Config;
