<template>
  <header class="main-header color-primary text-color-header">
    <nav class="brand-nav">
      <router-link
        to="/"
        class="logo text-replace"
        :style="{ backgroundImage: 'url(' + logo + ')' }"
        >Tribespot</router-link
      >
    </nav>
    <nav class="actions push">
        <div v-for="(item, index) in items" :key="index" class="large-menu-item">
          <router-link
            v-if="isLoggedIn && item.active"
            class="nav-item"
            :to="item.link"
          >
            <span class="menu-text">{{item.title[locale.replace(/-/g, "_")]}}</span>
          </router-link>
        </div>

      <!-- Login/logout -->
      <div v-if="!features.comingSoon && loginRequired" class="large-menu-item">
        <a class="nav-item" v-if="!isLoggedIn" @click="login">
          <font-awesome-icon :icon="logoutIcon" class="nav-icon log-icon" />
          <span class="menu-text">{{ $t("login") }}</span>
        </a>
        <a class="nav-item" v-else @click="logout">
          <font-awesome-icon :icon="loginIcon" class="nav-icon log-icon" />
          <span class="menu-text">{{ $t("logout") }}</span>
        </a>
      </div>

      <!-- Language -->
      <select
        class="selectpicker language-picker color-primary text-color-header"
        data-width="fit"
        v-model="locale"
        v-if="languages.length > 1"
      >
        <option v-for="(entry, index) in languages" :value="entry" :key="index">
          {{ $t(`languages.${entry}`) }}
        </option>
      </select>
    </nav>

    <Slide class="side-menu" right>
      <router-link
        v-for="(item, index) in items"
        :key="index"
        class="nav-item"
        :to="item.link"
      >
        <span>{{item.title[locale.replace(/-/g, "_")]}}</span>
      </router-link>
      <!-- Login / Logout -->
      <div
        v-if="
          authenticationType !== 'auth0' &&
            !$auth0.loading &&
            loginRequired &&
            !features.comingSoon
        "
      >
        <a class="nav-item" v-if="!isLoggedIn" @click="login">
          <font-awesome-icon :icon="logoutIcon" class="nav-icon log-icon" />
          <span class="menu-text">{{ $t("login") }}</span>
        </a>
        <a class="nav-item" v-if="isLoggedIn" @click="logout">
          <font-awesome-icon :icon="loginIcon" class="nav-icon log-icon" />
          <span class="menu-text">{{ $t("logout") }}</span>
        </a>
      </div>
    </Slide>
  </header>
</template>

<script>
import { faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Slide } from "vue-burger-menu";

export default {
  components: {
    Slide,
  },
  data() {
    return {
      isActive: true,
    };
  },
  methods: {
    login() {
      if (
        this.authenticationType == "basic" ||
        this.authenticationType == "code"
      ) {
        this.$router.push("/authentication");
      } else if (this.authenticationType == "auth0") {
        this.$auth0.loginWithRedirect();
      }
    },
    logout() {
      if (
        this.authenticationType == "basic" ||
        this.authenticationType == "code"
      ) {
        this.$store.dispatch("Auth/signOut");
        this.$router.push("/authentication");
      } else if (this.authenticationType == "auth0") {
        if (this.loginRequired) {
          this.$auth0.logout({
            returnTo: window.location.origin,
          });
        } else {
          this.$auth0.logout({
            returnTo: "/",
          });
        }
      }
    },
    social() {
      this.$router.push("/social");
    },
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale
    },
    items() {
      return this.$store.state.Header.items
    },
    settings() {
      return this.$store.state.Config.setting;
    },
    logo() {
      return this.$store.getters["Styling/logo"];
    },
    features() {
      return this.$store.state.Features.all;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
    authenticationType() {
      return this.$store.getters["Features/authenticationType"];
    },
    loginRequired() {
      return this.authenticationType !== "none";
    },
    isLoggedIn() {
      return this.$store.getters["Auth/isLoggedIn"];
    },
    logoutIcon() {
      return faSignOutAlt;
    },
    loginIcon() {
      return faSignInAlt;
    },
  },
  watch: {
    locale: {
      handler() {
        this.$root.$i18n.locale = this.locale;
        localStorage.setItem("locale", this.$root.$i18n.locale);
      },
    },
    settings: {
      deep: true,
      handler() {
        if (!this.languages || this.languages.length === 0) {
          return;
        }
        this.locale = this.$root.$i18n.locale;
        if (!this.languages.includes(this.locale)) {
          this.locale = this.languages[0];
        }
      },
    },
  },
  created() {
    const storedLocale = localStorage.getItem("locale");
    if (storedLocale) {
      this.$root.$i18n.locale = storedLocale;
    }
  },
};
</script>

<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.main-header {
  position: relative;
  z-index: 100;
  display: flex;
  width: 100%;
  padding: 0.6923em 2.3077em;
  min-height: 70px;

  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  > * {
    display: flex;
    align-items: center;
  }
}

.brand-nav {
  width: 300px;
  margin-right: 2em;
  .logo {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
    width: 100%;
    :hover {
      cursor: pointer;
      img {
        opacity: 0.8;
      }
    }
  }
}

.nav-item {
  margin-left: auto;
  display: flex;
  margin-left: 20px;
  .menu-text {
    font-size: 19px;
    font-weight: bold;
    // commented for Randstad
    // text-transform: uppercase;
  }
  .nav-icon {
    width: 20px;
    height: 20px;
    margin: auto 0.6154em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.nav-item:hover {
  cursor: pointer;
  opacity: 0.8;
}

.language-picker {
  font-size: 19px;
  margin-left: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
}

.language-picker:hover {
  cursor: pointer;
  opacity: 0.8;
}

.side-menu {
  display: none;
}

.bm-burger-button {
  // bottom: 20px !important;
  width: 30px !important;
  height: 24px !important;
  top: 24px !important;
}

.bm-menu {
  background-color: $color-primary;
}

.bm-burger-bars {
  background-color: $color-secondary !important;
}

@media only screen and (max-width: 872px) {
  .large-menu-item {
    display: none;
  }

  .language-picker {
    margin-right: 45px;
  }

  .side-menu {
    display: block;
  }
}
</style>
