


















































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { NavItem } from "../shared/types/nav-item.class";
import { Watch } from "vue-property-decorator";

const authModule = namespace("auth");
const featuresModule = namespace("Features");
const stylingModule = namespace("Styling");

@Component({})
export default class SideMenu extends Vue {
  navItems: NavItem[] = [
    new NavItem(this.$i18n.t("features") as string, "feature-config", "home"),
    new NavItem(
      this.$i18n.t("home-page") as string,
      "home-config",
      "home",
      "landingPage"
    ),
    new NavItem(this.$i18n.t("users") as string, "users", "user"),
    new NavItem(
      this.$i18n.t("notifications") as string,
      "notifications",
      "home"
    ),
    new NavItem(
      this.$i18n.t("livestreams") as string,
      "livestream-config",
      "home"
    ),
    new NavItem(this.$i18n.t("styling") as string, "style-config", "home"),
    new NavItem(
      this.$i18n.t("sponsors") as string,
      "style-config",
      "home",
      "sponsors"
    ),
    new NavItem(
      this.$i18n.t("registration") as string,
      "registration-config",
      "home",
      "registerForm"
    ),
    new NavItem(
      this.$i18n.t("static-pages") as string,
      "static-pages",
      "home"
    ),
    new NavItem(
      this.$i18n.t("header") as string,
      "header-config",
      "home"
    ),
  ];

  get currentRoute() {
    return this.$route.name;
  }

  @featuresModule.Getter("all")
  features!: any;

  @stylingModule.Getter("logo")
  logo!: string;

  @authModule.Mutation("logout")
  clearData!: () => void;

  logout() {
    this.$router.push({ name: "Login" });
  }
}
