import SettingsService from "@/services/SettingsService";
import StylingService from "@/services/StylingService";

interface StylingState {
  styling: any;
}

const Styling = {
  namespaced: true,
  state: {
    styling: {
      primary: "",
      secondary: "",
      tertiary: "",
      text_color_header: "",
      text_color_title: "",
      text_color_body: "",
      success: "",
      warning: "",
      danger: "",
      fontfamily: "",
      additional: {
        logo: "",
        background: "",
      },
    },
  },
  getters: {
    all: (state: StylingState) => state.styling,
    logo: (state: StylingState) => state.styling.additional.logo,
  },
  mutations: {
    SET_STYLING(state: { styling: any }, styling: any) {
      state.styling = styling;
    },
  },
  actions: {
    async generate() {
      await StylingService.generate()
    },
    async fetchAll({ commit }: any) {
      const styling = await SettingsService.fetchOnePublic("styling");
     
      commit("SET_STYLING", styling.setting_value);
    },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("styling", data);
      commit("SET_STYLING", response.setting_value);
    },
  },
} as any;

export default Styling;
