import SettingsService from "@/services/SettingsService";

const Dash = {
  namespaced: true,
  state: {
    content: {
      intro: {
        active: true,
        title: {
          en_US: "",
          nl_NL: "",
        },
        message: {
          en_US: "",
          nl_NL: "",
        },
      },
      blocks: [
        {
          type: "",
          active: true,
          title: "",
          description: {
            en: "",
            nl: "",
          },
        },
        {
          type: "",
          active: true,
          title: "",
          blocks: [
            {
              type: "",
              title: {
                en: "",
                nl: "",
              },
              description: "",
              image: "",
              url: "",
              openBlank: true,
            },
          ],
        },
      ],
      interaction: {
        active: true,
        type: "",
      },
    },
  },
  getters: {
    intro: (state: { content: any }) => state.content.intro,
    allBlocks: (state: { content: any }) => state.content.blocks,
    interaction: (state: { content: any }) => state.content.interaction,
  },
  mutations: {
    SET_CONTENT(state: { content: any }, content: any) {
      state.content = content;
    },
  },
  actions: {
    async getDashboardContent({ commit }: any) {
      const response = await SettingsService.fetchOne("dashboard");
      commit("SET_CONTENT", response.setting_value);
    },
    async updateDashboardContent({ commit }: any, data: any) {
      const response = await SettingsService.update("dashboard", data);
      commit("SET_CONTENT", response.setting_value);
    },
  },
} as any;

export default Dash;
