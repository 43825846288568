import axios from "@/plugins/axios.plugin";
import { PaginatedResult } from "@/shared/types/paginated-result.class";

export default class BaseService<T> {
  get endpoint() {
    return "/";
  }

  async create(entity: T): Promise<T> {
    const response = await axios.post<T>(this.endpoint, entity);
    return response.data;
  }

  async fetchAll(params?: any): Promise<PaginatedResult<T>> {
    const response = await axios.get<PaginatedResult<T>>(this.endpoint, {
      params,
    });

    return response.data;
  }

  async fetchOne(id: string): Promise<T> {
    const response = await axios.get<T>(`${this.endpoint}/${id}`);
    return response.data;
  }

  async update(id: string, entity: T): Promise<T> {
    const response = await axios.put<T>(`${this.endpoint}/${id}`, entity);
    return response.data;
  }

  async partialUpdate(id: string, entity: T): Promise<T> {
    const response = await axios.patch<T>(`${this.endpoint}/${id}`, entity);
    return response.data;
  }

  async delete(id: string): Promise<T> {
    const response = await axios.delete(`${this.endpoint}/${id}`);
    return response.data;
  }
}
