import axios from "axios";

const Translations = {
  namespaced: true,
  state: {
    translations: {},
  },
  getters: {
    translations: (state: { translations: any }) => state.translations,
    getTranslationByLanguage: (state: any) => (lang: any) => {
      return state.translations[lang];
    },
  },
  mutations: {
    SET_TRANSLATIONS(state: { translations: any }, translations: any) {
      state.translations = translations;
    },
  },
  actions: {
    async getTranslations({ commit }: any) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/settings/public/translations`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          responseType: "json",
        }
      );
      commit("SET_TRANSLATIONS", response.data.setting_value);
    },
  },
} as any;

export default Translations;
