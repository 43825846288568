import SettingsService from "@/services/SettingsService";

const Header = {
  namespaced: true,
  state: {
    items: [],
    item: {}
  },
  getters: {
    allItems: (state: { items: any }) => state.items,
    item: (state: {item: any }) => state.item,
  },
  mutations: {
    SET_CURRENT(state: { item: any }, item: any) {
      state.item = item
    },
    SET_ALL(state: { items: any }, items: any) {
      state.items = items
    },
    EMPTY_CURRENT(state: { item: any }) {
      state.item = {
        active: false,
        title: {
          en_US: "",
          nl_NL: ""
        },
        link: ""
      }
    },
  },
  actions: {
    async fetchAll({ commit }: any) {
      const items = await SettingsService.fetchOnePublic("header");
      commit("SET_ALL", items.setting_value);
    },
    async update({ commit }: any, data: any) {
      const response = await SettingsService.update("header", data);
      commit("SET_ALL", response);
    }
  }
} as any

export default Header