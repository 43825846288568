import SettingsService from "@/services/SettingsService";

interface FeaturesState {
  all: { [key: string]: any };
}

const Features = {
  namespaced: true,
  state: {
    all: {
      setting_value: {},
    },
  },
  getters: {
    all: (state: FeaturesState) => state.all.setting_value,
    loginRequired: (state: FeaturesState) =>
      state.all?.setting_value?.authentication?.type !== "none",
    sponsors: (state: FeaturesState) => state.all?.setting_value?.sponsors,
    authenticationType: (state: FeaturesState) =>
      state.all?.setting_value?.authentication?.type,
  },
  mutations: {
    SET_ALL(state: FeaturesState, features: any) {
      state.all = features;
    },
  },
  actions: {
    async fetchAll({ commit }: any, filter: any) {
      const features = await SettingsService.fetchOnePublic("features");
      commit("SET_ALL", features);
    },
    async update({ commit }: any, payload: any) {
      const result = await SettingsService.update("features", payload);
    },
  },
} as any;

export default Features;
