<template>
  <div id="app" class="color-primary background-image">
    <Header :settings="settings" :currentUser="currentUser" />
    <slot />
    <FreshChat
      v-if="
        settings.support.type !== null &&
          settings.support.type.toLowerCase() == 'freshchat'
      "
      :currentUser="currentUser"
      :settings="settings"
      class="chat"
    />
    <Popup />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import FreshChat from "@/components/chat/FreshChat.vue";
import { i18n } from "@/plugins/i18n";
import Header from "@/components/Header.vue";
import Popup from "@/components/Popup.vue";

export default {
  name: "App",
  components: {
    Footer,
    Popup,
    FreshChat,
    Header,
  },
  methods: {
    setTheme(colors, font, backgroundImage, logoImage) {
      let style = document.documentElement.style;

      for (let key of Object.keys(colors)) {
        style.setProperty("--theme-color-" + key, colors[key]);
      }

      style.setProperty("--theme-font-family", font);
      style.setProperty("--theme-background-image", backgroundImage);
      style.setProperty("--theme-logo", logoImage);
    },
  },
  metaInfo() {
    return {
      title: this.settings.client.name,
    };
  },
  computed: {
    settings() {
      return this.$store.state.Config.setting;
    },
    styling() {
      return this.$store.state.Styling.styling;
    },
    pages() {
      return this.$store.state.Pages.pages;
    },
    currentUser() {
      return this.$store.getters["Auth/currentUser"];
    },
    layout() {
      if (this.$route.meta && this.$route.meta.protected) {
        return "sidebar-layout";
      }
      return "no-sidebar-layout";
    },
  },
  async created() {
    // await this.$store.dispatch("Dash/getDashboardContent");
    // await this.$store.dispatch("Livestreams/getLivestream");
    // await this.$store.dispatch("Livestreams/fetchAll");
    // await this.$store.dispatch("Attachments/getAllAttachments");
    // await this.$store.dispatch("Translations/getTranslations");
    await this.$store.dispatch("Styling/fetchAll");
    await this.$store.dispatch("Pages/fetchAll");
    await this.$store.dispatch("Header/fetchAll");

    const favicon = document.getElementById("favicon");
    favicon.href = this.settings.client.favicon;
    localStorage.setItem("lang", i18n.locale);

    let colors = {
      primary: this.styling.primary,
      secondary: this.styling.secondary,
      text_color_header: this.styling.text_color_header,
      text_color_title: this.styling.text_color_title,
      text_color_body: this.styling.text_color_body,
      warning: this.styling.warning,
      success: this.styling.success,
      danger: this.styling.danger,
    };

    let font = this.styling.fontfamily;

    let backgroundImage = "url('')";
    let logoImage = "url('')";

    if (this.styling.additional.background) {
      backgroundImage = "url(" + this.styling.additional.background + ")";
    }

    if (this.styling.additional.logo) {
      logoImage = "url(" + this.styling.additional.logo + ")";
    }

    this.setTheme(colors, font, backgroundImage, logoImage);
  },
};
</script>

<style lang="scss">
// @import "http://localhost:8000/storage/style.css";
@import "/api/public/storage/style.css";
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.router-class {
  padding-top: 20px;
}
.color-primary {
  background-color: $color-primary !important;
}

.color-secondary {
  background-color: $color-secondary !important;
}

.text-color-header {
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    color: $text-color-header;
  }
  color: $text-color-header;
}

.text-color-title {
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    color: $text-color-title;
  }
  color: $text-color-title;
}

.text-color-body {
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    color: $text-color-body;
  }
  color: $text-color-body;
}

html,
body {
  min-width: 0px !important;
  width: 100%;
  height: 100%;
  overflow: auto !important;
}

#app {
  background-color: $color-primary;
  overflow: auto;
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-image {
  background-image: $background-image;
  position: absolute;
  width: 100%;
  background-size: cover;
  overflow: auto;
}

body {
  overflow: auto !important;
}

body > #zmmtg-root {
  display: none !important;
}

#zmmtg-root,
.meeting-client,
.meeting-client-inner {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

#wc-footer {
  bottom: auto !important;
  width: 100% !important;
}

#dialog-join {
  width: 100% !important;
}

#sv-active-video,
.active-main,
#sv-active-speaker-view,
.main-layout {
  height: 100% !important;
  width: 100% !important;
}

.suspension-window {
  transform: translate(-444px, 10px) !important;
}
</style>
