import axios from "@/plugins/axios.plugin";
import FilesService from "@/services/FilesService";

const Attachments = {
  namespaced: true,
  state: {
    attachments: {},
    attachment: []
  },
  getters: {
    allAttachments: (state: { attachments: any }) => state.attachments,
    attachment: (state: {attachment: any }) => state.attachment
  },
  mutations: {
    SET_ATTACHMENT(state: { attachment: any }, attachment: any) {
      state.attachment = attachment
    },
    SET_ALL_ATTACHMENTS(state: { attachments: any }, attachments: any) {
      state.attachments = attachments
    },
  },
  actions: {
    async getAttachment({ commit }: any, id: any) {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/attachments/${id}`, {
        headers: { 
          "Access-Control-Allow-Origin": "*"
        },
        responseType: 'json',
      })
      commit('SET_ATTACHMENT', response.data.setting_value)
    },
    async getAllAttachments({ commit }) {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/attachments`, {
        headers: { 
          "Access-Control-Allow-Origin": "*"
        },
        responseType: 'json',
      })
      commit('SET_ALL_ATTACHMENTS', response.data)
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.append("file", file);
      const response = await FilesService.upload(formData)
      return response.data.url
    } 
  }
} as any

export default Attachments